<template>
  <el-card class="form-container" shadow="never">
    <el-form :model="orderSetting"
             ref="orderSettingForm"
             :rules="rules"
             label-width="150px">
      <el-form-item label="订单超过：" prop="payOvertime">
        <el-input v-model="orderSetting.payOvertime" class="input-width">
          <template slot="append">分</template>
        </el-input>
        <span class="note-margin">未付款，订单自动关闭</span>
      </el-form-item>
      <el-form-item label="发货超过：" prop="recvOvertime">
        <el-input v-model="orderSetting.recvOvertime" class="input-width">
          <template slot="append">天</template>
        </el-input>
        <span class="note-margin">未收货，自动收货，订单完成</span>
      </el-form-item>
      <el-form-item label="订单完成超过：" prop="refundOvertime">
        <el-input v-model="orderSetting.refundOvertime" class="input-width">
          <template slot="append">天</template>
        </el-input>
        <span class="note-margin">自动完成交易，不能申请售后/退款</span>
      </el-form-item>
      <el-form-item label="订单完成超过：" prop="commentOvertime">
        <el-input v-model="orderSetting.commentOvertime" class="input-width">
          <template slot="append">天</template>
        </el-input>
        <span class="note-margin">不能再评论商品</span>
      </el-form-item>
      <el-form-item label="返佣对象" prop="RebateMode">
        <el-select v-model="orderSetting.RebateMode" class="input-width">
          <el-option
            v-for="item in rebateModeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="confirm('orderSettingForm')"
          type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getConfigSetting, updateConfigSetting } from '../../network/notify'

const defaultOrderSetting = {
  id: null,
  payOvertime: 0,
  recvOvertime: 0,
  refundOvertime: 0,
  commentOvertime: 0,
  RebateMode: 0
}
const checkTime = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('时间不能为空'))
  }
  var intValue = parseInt(value)
  if (!Number.isInteger(intValue)) {
    return callback(new Error('请输入数字值'))
  }
  callback()
}
export default {
  name: 'Setting',
  data () {
    return {
      orderSetting: Object.assign({}, defaultOrderSetting),
      rules: {
        payOvertime: { validator: checkTime, trigger: 'blur' },
        recvOvertime: { validator: checkTime, trigger: 'blur' },
        refundOvertime: { validator: checkTime, trigger: 'blur' },
        commentOvertime: { validator: checkTime, trigger: 'blur' }
      },
      orderConfigType: 1,
      rebateModeOptions: [
        {
          label: '全部用户',
          value: 0
        },
        {
          label: '仅店长',
          value: 1
        }
      ]
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    confirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否要提交修改?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.orderSetting.commentOvertime = parseInt(this.orderSetting.commentOvertime)
            this.orderSetting.payOvertime = parseInt(this.orderSetting.payOvertime)
            this.orderSetting.refundOvertime = parseInt(this.orderSetting.refundOvertime)
            this.orderSetting.recvOvertime = parseInt(this.orderSetting.refundOvertime)
            this.orderSetting.rebateMode = parseInt(this.orderSetting.rebateMode)
            updateConfigSetting({
              content: this.orderSetting,
              desc: '订单配置信息',
              type: this.orderConfigType
            }).then(res => {
              if (res.status !== 200) {
                return this.alertMessage('修改订单配置信息失败', 'error')
              }
              this.alertMessage('修改订单配置信息成功', 'success')
            })
          })
        } else {
          this.$message({
            message: '提交参数不合法',
            type: 'warning'
          })
          return false
        }
      })
    },
    getDetail () {
      getConfigSetting({
        type: this.orderConfigType
      }).then(res => {
        console.log('get config setting:', res)
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取订单配置数据获取失败', 'error')
          return
        }
        this.orderSetting = result.data.content
        console.log('orderSetting is:', this.orderSetting)
      }, err => {
        console.log('get data err:', err)
      })
    }
  }
}
</script>
<style scoped>
.input-width {
  width: 50%;
}

.note-margin {
  margin-left: 15px;
}
</style>
